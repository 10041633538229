<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <div class="commodity-management-bg">
          <b-container fluid>
            <h2 class="page-title">商品管理</h2>
            <b-row class="b-row">
              <b-col class="b-col" sm="12" md="6" lg="6" xl="6">
                <div class="option-wrap">
                  <div class="search-wrap">
                    <el-input v-model="searchKeyword" prefix-icon="el-icon-search" placeholder="请输入关键字" size="small"></el-input>
                  </div>
                  <div class="limit-time-wrap">
                    <el-date-picker
                      v-model="limitDate"
                      size="small"
                      type="daterange"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :default-time="['00:00:00', '23:59:59']">
                    </el-date-picker>
                  </div>
                </div>
              </b-col>
              <b-col class="b-col" sm="12" md="6" lg="6" xl="6">
                <div class="select-wrap">
                  <div class="select-option">
                    <el-select v-model="brandClassify" placeholder="请选择品牌分类" size="small">
                      <el-option
                        v-for="item in brandOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="select-option">
                    <el-select v-model="brandModel" size="small">
                      <el-option
                        v-for="item in brandModelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="select-option">
                    <el-select v-model="brandModel" size="small">
                      <el-option
                        v-for="item in brandModelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="commodity-list">
              <table>
                <thead>
                  <tr>
                    <th>
                      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChange"></el-checkbox>
                    </th>
                    <th>商品编号</th>
                    <th>商品型号</th>
                    <th>商品名称</th>
                    <th>运营商折扣</th>
                    <th>分销商折扣</th>
                    <th>会员折扣</th>
                    <th>品牌</th>
                    <th>风格</th>
                    <th>分类</th>
                    <th>销售价</th>
                    <th>销售数量</th>
                    <th>赠送积分</th>
                    <th>是否上架</th>
                    <th>上架时间</th>
                    <th>开启促销</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in 10" :key="index">
                    <td>
                      <el-checkbox></el-checkbox>
                    </td>
                    <td>5013284</td>
                    <td>ZD-TTY-2120-12C</td>
                    <td>北欧风格 全实木简约小户型1.2米单人床</td>
                    <td>未设置</td>
                    <td>未设置</td>
                    <td>未设置</td>
                    <td>团团圆</td>
                    <td>北欧/现代</td>
                    <td>卧室</td>
                    <td>¥2450.00</td>
                    <td>37</td>
                    <td></td>
                    <td>
                      <el-switch v-model="val"></el-switch>
                    </td>
                    <td>2021-06-03 11:47:21</td>
                    <td>
                      <el-switch v-model="test"></el-switch>
                    </td>
                    <td>
                      <div class="operation">
                        <i class="el-icon-setting" title="设置"></i>
                        <i class="el-icon-edit" title="编辑"></i>
                        <i class="el-icon-delete" title="删除" @click="del"></i>
                        <i class="el-icon-document-copy" title="复制商品"></i>
                        <i class="el-icon-sunny" title="生成太阳码"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-container>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="openDialog"
      width="60%"
      center>
      <div class="commodity-attribute">
        <table>
          <thead>
            <tr>
              <th>
                <el-checkbox></el-checkbox>
              </th>
              <th>序号</th>
              <th>参数名称</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in 2" :key="index">
              <td><el-checkbox></el-checkbox></td>
              <td>{{item}}</td>
              <td>规格/尺寸</td>
              <td>
                <div class="operation">
                  <i class="el-icon-edit" title="编辑"></i>
                  <i class="el-icon-delete" title="删除" @click="del"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openDialog = false">取 消</el-button>
        <el-button type="primary" @click="openDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  export default {
    components: {
      headNav,
      sideBar
    },
    data (){
      return {
        searchKeyword: "",
        limitDate: "",
        brandClassify: "",
        brandOptions: [
          {
            value: '0',
            label: '全部'
          },
          {
            value: '1',
            label: '臻选'
          },
          {
            value: '2',
            label: '严选'
          },
          {
            value: '3',
            label: '优选'
          }
        ],
        brandModel: "",
        brandModelOptions: [
          {
            value: '0',
            label: '没有品牌商品'
          },
          {
            value: '1',
            label: '品牌商品'
          }
        ],
        isIndeterminate: true,
        checkAll: false,
        val: true,
        test: false,
        openDialog: true
      }
    },
    methods: {
      // 全选
      checkAllChange(){
        
      },
      // 删除商品
      del(){
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/commodityManagement/commodityManagement";
</style>
